<div style="height: 100vh">
    <mat-toolbar color="secondary" style="height:50px;  min-height: 50px;">
        <mat-toolbar-row
            style="height:50px; padding-left: 0; background-color: var(--bkg-color); color: var(--text-color); border-bottom: 1px solid #E0E0E0;">
            <button mat-icon-button (click)="sidenav.toggle()" fxShow="true" fxHide.gt-sm>
                <mat-icon>menu</mat-icon>
            </button>
            <span style="border-right:1px solid #e8e8e8; height: 100%; display: flex; align-items: center; padding: 0 16px;">
                <img style="width: 15.06px; height: 20.88px; left: 17px; top: calc(50% - 20.88px/2 - 0.56px);"
                    src="assets/logo.png">
            </span>
            <div fxShow="true" fxHide.lt-md style="height: 100%;">
                <a routerLink="/dashboard" mat-button class="navigation-element" routerLinkActive="active"
                    *ngIf="localEnvironment.dashboard === true">Dashboard</a>
                    <a [routerLink]="'/automation/' + integrations[0]?.id" mat-button
                        class="navigation-element" routerLinkActive="active"><span>Automation</span></a>
            </div>
            <span class="menu-spacer"></span>
            <button mat-icon-button *ngIf="isDarkModeAvailable" (click)="toggleDarkMode()">
                <mat-icon *ngIf="isDarkModeEnabled">light_mode</mat-icon>
                <mat-icon *ngIf="!isDarkModeEnabled">dark_mode</mat-icon>
            </button>

            <a mat-button [matMenuTriggerFor]="usermenu" class="navigation-element">{{currentUser}}</a>
            <mat-menu #usermenu="matMenu">
                <button mat-menu-item (click)="logout()">
                    <span>Logout</span>
                </button>
                <div style="padding: 0px 16px 8px 16px" (click)="doNothing($event)">{{beVersion}}</div>
                <div style="padding: 0px 16px;" (click)="doNothing($event)">{{feVersion}}</div>
            </mat-menu>
        </mat-toolbar-row>
    </mat-toolbar>

    <mat-sidenav-container style="height: calc(100vh - 50px);">
        <mat-sidenav #sidenav>
            <mat-nav-list>
                <a routerLink="/dashboard" mat-list-item *ngIf="localEnvironment.dashboard === true">Dashboard</a>
                <a [routerLink]="'/automation/' + integrations[0]?.id" mat-list-item>Automation</a>
                <a mat-list-item (click)="logout()">Logout</a>
            </mat-nav-list>
        </mat-sidenav>
        <mat-sidenav-content style="display: flex; flex-direction: column; background-color: var(--bkg-color)">
            <div class="content">
                <router-outlet></router-outlet>
            </div>

        </mat-sidenav-content>
    </mat-sidenav-container>
</div>
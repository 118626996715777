import { NgModule } from '@angular/core';
import { Routes, RouterModule } from "@angular/router";
import {AuthGuard} from './helpers/auth.guard';
import { environment } from "src/environments/environment";

const routes: Routes = [
  {
    path: 'automation',
    loadChildren: () =>
      import('./modules/automation/automation.module').then(mod => mod.AutomationModule),
    canActivate: [AuthGuard]
  },
  ...(environment.dashboard === true
    ? [
        {
          path: 'dashboard',
          loadChildren: () =>
            import('./modules/dashboard/dashboard.module').then(mod => mod.DashboardModule),

          canActivate: [AuthGuard]
        }
      ]
    : []),
  // otherwise redirect to home
  {
    path: '**',
    redirectTo: environment.dashboard === true ? '/dashboard' : '/automation'
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

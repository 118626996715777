import { Injectable } from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {firstValueFrom} from 'rxjs';
import {environment} from '../../environments/environment';
import {SuiteAction} from '../models/suiteAction';
import {Integration} from '../models/integration';

@Injectable({
  providedIn: 'root'
})
export class RepositoryService {
  currentIntegrationId: string;
  availableIntegrations;

  constructor(private httpClient: HttpClient) {}

  public actionAPI(url: string) {
    return firstValueFrom(
      this.httpClient.post<any>(url, {title: 'qaron'})
    ).then(data => {
      return <SuiteAction>data;
    });
  }

  public getIntegrations(): Promise<Integration[]> {
    return firstValueFrom(
      this.httpClient.get(environment.apiUrl + '/api/gitlab-integrations')
    ).then(data => {
      return data as Integration[];
    });
  }
}



import { Injectable } from '@angular/core';
import { MatSnackBar } from "@angular/material/snack-bar";


@Injectable({ providedIn: "root" })
export class AlertService {
  constructor(private snackBar: MatSnackBar) {}

  success(message: string) {
    this.snackBar.open(message, undefined, {
      horizontalPosition: 'start',
      verticalPosition: 'bottom',
      duration: 3000,
      panelClass: ['green-snackbar']
    });
  }

  error(message: string) {
    this.snackBar.open(message, undefined, {
      horizontalPosition: "start",
      verticalPosition: "bottom",
      duration: 3000,
      panelClass: ["red-snackbar"],
    });
  }
}

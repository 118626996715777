import { KeycloakService } from "keycloak-angular";
import { environment } from "src/environments/environment";

export function initializeKeycloak(keycloak: KeycloakService) {
  return () =>
    keycloak.init({
      config: {
        url: environment.keycloakUrl,
        realm: environment.keycloakRealm,
        clientId: environment.keycloakClientId,
      },
      loadUserProfileAtStartUp: true,
      initOptions: {
        pkceMethod: "S256",
        // must match to the configured value in keycloak
        redirectUri: environment.appUrl,
        // this will solved the error
        checkLoginIframe: false,
      },
    });
}

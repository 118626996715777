import { HttpClient } from "@angular/common/http";
import { Component } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { KeycloakService } from "keycloak-angular";
import { environment } from "src/environments/environment";
import {RepositoryService} from './services/repository.service';
import {Integration} from './models/integration';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'Qaron';
  currentUser;
  localEnvironment;
  beVersion;
  feVersion = environment.version;

  isDarkModeAvailable = environment.darkModeAvailable;
  isDarkModeEnabled = environment.darkModeAvailable && localStorage.getItem('darkMode') === 'true';

  integrations: Integration[] = [];

  constructor(
    private titleService: Title,
    private keycloak: KeycloakService,
    private httpClient: HttpClient,
    private repositoryService: RepositoryService
  ) {
    this.titleService.setTitle(this.title);
    this.localEnvironment = environment;

    this.keycloak.isLoggedIn().then(val => {
      this.currentUser = this.keycloak.getUsername();
    });

    this.httpClient.get(environment.apiUrl + '/actuator/info').subscribe(data => {
      this.beVersion = (data as any).git?.build?.version;
    });

    this.repositoryService.getIntegrations().then(ints => (this.integrations = ints));

    if (this.isDarkModeEnabled) document.body.classList.toggle('dark-theme');
  }

  async logout() {
    await this.keycloak.logout(environment.appUrl); // TODO here
  }

  toggleDarkMode() {
    document.body.classList.toggle('dark-theme');
    this.isDarkModeEnabled = !this.isDarkModeEnabled;
    localStorage.setItem('darkMode', this.isDarkModeEnabled.toString());
  }

  doNothing(event) {
    event.stopPropagation();
  }
}

// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
         production: false,
         apiUrl: window['env'].apiUrl || 'default',
         keycloakUrl: window['env'].keycloakUrl || 'https://a.qaron.sk', //''http://127.0.0.1:9080/', //
         keycloakRealm: window['env'].keycloakRealm || 'qaron-dev',
         keycloakClientId: window['env'].keycloakClientId || 'web_app',
         appUrl: window['env'].appUrl || 'http://localhost:4200',
         version: window['env'].gitClosestTagCommit || 'v0.0.0',
         dashboard: window['env'].dashboard || true,
         darkModeAvailable: window['env'].darkModeAvailable === 'true' || true
       };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
